import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  Tooltip
} from "chart.js";

ChartJS.register(LinearScale, CategoryScale, LineElement, PointElement, Filler, Tooltip);

// Chart.js plugin to draw a vertical line at the retirementIndex
const drawVerticalLinePlugin = {
  id: 'drawVerticalLinePlugin',
  afterDatasetsDraw: function (chart) {
    const ctx = chart.ctx;
    const meta = chart.getDatasetMeta(0); // Assuming only one dataset
    const retirementIndex = chart.options.plugins.drawVerticalLinePlugin.retirementIndex;

    if (meta && retirementIndex !== undefined && retirementIndex !== -1) {
      const element = meta.data[retirementIndex];
      if (element) {
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(element.x, element.y);
        ctx.lineTo(element.x, chart.chartArea.bottom);
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#FC6702';
        ctx.stroke();

        // Draw a dot at the top of the vertical line
        ctx.beginPath();
        ctx.arc(element.x, element.y, 5, 0, Math.PI * 2);
        ctx.fillStyle = '#FC6702';
        ctx.fill();

        ctx.restore();
      }
    }
  }
};

// Register the plugin with Chart.js
ChartJS.register(drawVerticalLinePlugin);

const GrothGraph = ({ chartData }) => {
  const [retirementIndex, setRetirementIndex] = useState(chartData.retirementIndex);

  // Effect to update retirementIndex when chartData changes
  useEffect(() => {
    setRetirementIndex(chartData.retirementIndex);
  }, [chartData]);

  // Extracting data from chartData
  const dataPoints = chartData.data.map((dataPoint) => ({
    x: dataPoint.age,
    y: dataPoint.btcStack
  }));

  // Generate labels showing only every 5 years
  const labels = chartData.data.map((dataPoint, index) => {
    if (index === 0 || dataPoint.age % 5 === 0) {
      return dataPoint.age.toString();
    } else {
      return '';
    }
  });

  const data = {
    datasets: [
      {
        label: "BTC Value",
        data: dataPoints,
        borderColor: "#FC6702",
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx } = chart;

          const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
          gradient.addColorStop(0, "rgba(255, 165, 0, 0.8)");
          gradient.addColorStop(1, "rgba(255, 165, 0, 0)");

          return gradient;
        },
        fill: true,
        borderWidth: 2,
        pointBorderWidth: 0,
        tension: 0.4,
      },
    ],
  };

  const options = {
    plugins: {
      drawVerticalLinePlugin: { retirementIndex }, // Pass retirementIndex as a plugin option
      legend: { display: true },
      tooltip: {
        displayColors: false,
        callbacks: {
          beforeLabel: function (context) {
            const index = context.dataIndex;
            const label = context.chart.data.datasets[0].data[index].x;
            return `🔵 Age: ${label}`;
          },
          label: function (context) {
            const value = context.raw.y;
            return `💰 BTC Value: $${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`; // Using a money bag emoji as the icon
          },
          labelTextColor: function () {
            return '#fff';
          },
          afterLabel: function () {
            return '';
          },
          title: function () {
            return '';
          }
        }
      }
    },
    interaction: { intersect: false },
    layout: { padding: { bottom: 10 } },
    maintainAspectRatio: true,
    scales: {
      y: {
        type: 'linear',
        ticks: {
          color: "#787878",
          font: { size: 14, weight: 500 },
          callback: function (value) {
            return `$${value.toLocaleString()}`;
          },
        },
        grid: {
          color: "#f5f5f5",
        },
        border: {
          dash: [2, 2],
        },
        title: {
          display: true,
          text: 'BTC Value',
          color: '#787878',
          font: { size: 16, weight: 'bold' },
          padding: { bottom: 10 },
        }
      },
      x: {
        type: 'linear',
        ticks: {
          color: "#787878",
          font: { size: 14, weight: 500 },
          maxRotation: 0,
          autoSkip: false,
          callback: function (value) {
            return value; // Show all ticks
          },
        },
        grid: {
          color: "#f5f5f5",
        },
        border: {
          dash: [2, 2],
        },
        title: {
          display: true,
          text: 'Age',
          color: '#787878',
          font: { size: 16, weight: 'bold' },
          padding: { top: 10 },
        }
      },
    },
  };

  return (
    <div className="pt-8 pr-8 flex justify-center items-center">
      <Line data={data} options={options} />
    </div>
  );
};

export default GrothGraph;
