import React from 'react'

const Footer = () => {
  return (
    <footer className="w-full border-t border-gray-200 py-4">
      <div className="w-full flex justify-between items-center max-w-[1440px] sm:flex-row flex-col pl-14 pr-8" >
        <div className="text-left text-gray-500 text-[14px] ">
          Made with <span className="text-red-500">❤️</span> by SaasCraft Ventures
        </div>
        <div className="text-right text-gray-500 ">
          <a href="https://insigh.to/b/btc-retirement-calculator" className="hover:underline text-[14px]">
            ● Suggest our next feature
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer