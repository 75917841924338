import React, { useState, useEffect } from "react";

const InputField = ({ label, value, onChange, prefix, suffix, className, className2 = "", increaseDecrease }) => {
  const [inputWidth, setInputWidth] = useState(4);

  const formatValueForDisplay = (value) => {
    if (typeof value === 'number') {
      return value.toLocaleString();
    } else {
      return value;
    }
  };

  useEffect(() => {
    const formattedValue = formatValueForDisplay(value);
    setInputWidth(formattedValue.length);
  }, [value]);

  const handleInputChange = (e) => {
    const inputVal = e.target.value;
    const numericValue = inputVal.replace(/,/g, '');
    onChange(numericValue);
    setInputWidth(inputVal.length);
  };

  return (
    <div className={className}>
      <label className="block text-[#787878] text-[14px] font-[400] mb-2">
        {label}
      </label>
      <div className={`border-[1px] flex items-center rounded-[16px] w-full py-1 bg-[#FFFFFF] font-[600] text-[18px] text-[#232526] px-[16px] ${increaseDecrease ? 'justify-center' : 'justify-between'} ${className2}`}>
        {prefix && (
          <span className="pe-1 text-[#FC6702] text-[18px] font-[600]">
            {prefix}
          </span>
        )}
        <input
          type="text"
          value={formatValueForDisplay(value)}
          onChange={handleInputChange}
          style={increaseDecrease && 
            { width: `${inputWidth + 1}ch` }}
          className="outline-none w-full text-[#232526] font-bold font-[sans-serif] h-[50px]"
        />
        {suffix && (
          <div className="flex items-center">
            <span className="bg-[#E5E5E5] w-[1px] h-[24px] mx-2"></span>
            <span className="text-[#FC6702] text-[18px] font-[600]">
              {suffix}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default InputField;
