import { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";

const useCalculatorData = (userData) => {
  const [outputData, setOutputData] = useState({
    currentBTCValue: 0,
    portfolioBalance: 0,
    isEnoughToRetire: false,
  });
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd",
          {
            headers: {
              accept: "application/json",
              "x-cg-demo-api-key": "CG-owA85JjghmZZMuCJKKdt8gxW",
            },
          }
        );
        const btcData = response.data.bitcoin;
        setOutputData((prevState) => ({
          ...prevState,
          currentBTCValue: Number(btcData.usd),
        }));
        calculateRetirementSufficiency(btcData.usd);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const calculateRetirementSufficiency = (btcValue) => {
    const {
      currentAge,
      currentBTC,
      monthlyExpenses,
      monthlyContribution,
      retirementAge,
      expectancyAge,
      annualInflation,
    } = userData;

    const genesisDate = dayjs("2009-01-03");
    const currentYear = dayjs().year();
    const startDate = dayjs(new Date(currentYear, 0, 31)); // Last day of January of the current year

    // Calculate the number of months to retirement
    const monthsToLife = (expectancyAge - currentAge) * 12;
    const monthsToRetirement = (retirementAge - currentAge) * 12;
    let totalBTCContributions = 0;
    let currentMonth = startDate;
    const dataPoints = [];
    let totalBTCAtRetirement = 0;
    dataPoints.push({ age: 40, btcStack: btcValue * currentBTC });

    // Calculate BTC contributions until retirement using the Power Law model
    for (let i = 1; i <= monthsToLife; i++) {
      let daysSinceGenesisCurrentMonth = currentMonth.diff(genesisDate, "day");

      // Forecast BTC price for the current month
      const forecastedBTCPrice =
        1.0117e-17 * Math.pow(daysSinceGenesisCurrentMonth, 5.82);
      // Monthly BTC contribution
      if (i > monthsToRetirement) {
        totalBTCContributions -= monthlyExpenses / forecastedBTCPrice;
      } else {
        totalBTCContributions += monthlyContribution / forecastedBTCPrice;
      }

      if (i % 12 === 0) {
        const age = currentAge + Math.floor(i / 12);
        const btcStack =
          (parseFloat(currentBTC) + totalBTCContributions) * forecastedBTCPrice;
        if (age === retirementAge) {
          totalBTCAtRetirement = btcStack;
        }
        dataPoints.push({ age, btcStack });
      }

      currentMonth = currentMonth.add(1, "month").endOf("month");
    }

    const retirementYears = expectancyAge - retirementAge;
    const inflationAdjustedExpenses =
      monthlyExpenses * Math.pow(1 + annualInflation / 100, retirementYears);
    const retirementAmount = inflationAdjustedExpenses * 12 * retirementYears;

    // Determine if the BTC stack is enough to retire
    const isEnoughToRetire = totalBTCAtRetirement >= retirementAmount;

    const retirementIndex = dataPoints.findIndex(
      (dataPoint) => dataPoint.age === retirementAge
    );

    // Update output data
    setOutputData((prevState) => ({
      ...prevState,
      portfolioBalance: totalBTCAtRetirement,
      isEnoughToRetire,
    }));

    setChartData({ data: dataPoints, retirementIndex: retirementIndex });
  };

  useEffect(() => {
    calculateRetirementSufficiency(outputData.currentBTCValue);
  }, [
    userData.currentAge,
    userData.currentBTC,
    userData.monthlyExpenses,
    userData.monthlyContribution,
    userData.retirementAge,
    userData.expectancyAge,
    userData.annualInflation,
    userData.forecastBTCPrice,
    outputData.currentBTCValue,
  ]);

  return { outputData, chartData };
};

export default useCalculatorData;
